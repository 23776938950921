body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

    /*
    background: rgb(100,100,147);
    background: linear-gradient(180deg, rgba(100,100,147,1) 0%, rgba(196,149,196,1) 60%, rgba(255,255,255,1) 100%);
    */
    background: #abb9cc;
    background: linear-gradient(to bottom, #18547a 0%,#abb9cc 70%,#abbace 100%);
    min-height: 100vh;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a, a:visited{
  text-decoration: none;
}

.blueLink a:visited{
  color: rgb(59,108,201);
}

.blueLink a{
  color: rgb(59,108,201);
}

.lightBlueLink a:visited{
  color: rgb(126, 158, 255);
}

.lightBlueLink a{
  color: rgb(126, 158, 255);
  transition: 0.25s;
}

.lightBlueLink a:hover{
  color: rgb(183, 201, 255);
}

.twoColumnContainer{
  display:flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
}

.twoColumnItem{
  width: auto;
  margin-right: 4em;
}

.twoColumnItemLink{
  width: auto;
  margin: 0em auto 1.5em auto ;
  transition: all 0.6s ease;
}

.twoColumnItemLink:hover{
  transform: scale(1.075);
  background: rgba(50,50,150,0.1);
}